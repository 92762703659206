<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div class="language-switcher">
    <CButton type="button" @click="setLanguage('ru')" v-if="isEnglish()">
      <CIcon name="cif-us" size="xl" />
    </CButton>
    <CButton type="button" @click="setLanguage('en')" v-if="isRussian()">
      <CIcon name="cif-ru" size="xl" />
    </CButton>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      language: "",
    };
  },
  mounted() {
    this.language = window.localStorage.language || "en";
  },
  methods: {
    isEnglish() {
      return this.language === "en";
    },
    isRussian() {
      return this.language === "ru";
    },
    setLanguage(lang) {
      this.language = lang;
      window.localStorage.setItem("language", lang);
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>
