<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="p-4 password-restore-container">
            <CCardBody
              v-if="!changePasswordForm.code"
              class="restore-password-content"
            >
              <div class="switcher-wrapper">
                <language-switcher></language-switcher>
              </div>
              <h1>{{ $t("restorePassword.title") }}</h1>
              <div class="email-content" v-if="!emailSent">
                <p class="text-muted">{{ $t("restorePassword.subtitle") }}</p>
                <form v-on:keydown.enter.prevent="submitEmail">
                  <CInput
                    :placeholder="$t('restorePassword.email')"
                    v-model="form.email"
                    :isValid="$v.form.email.isValidEmail"
                    :tooltipFeedback="false"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-at" />
                    </template>
                  </CInput>
                </form>
                <CRow class="restore-password-actions">
                  <CCol col="6" class="text-left">
                    <CButton
                      color="primary"
                      class="px-4"
                      :disabled="!isEmailFormValid"
                      @click="submitEmail()"
                      >{{ $t("common.continue") }}</CButton
                    >
                  </CCol>
                  <CCol col="6" class="text-right align-self-center">
                    <CLink @click="goToLogin()" href="#">{{
                      $t("login.enter")
                    }}</CLink>
                  </CCol>
                </CRow>
              </div>

              <div v-else class="success-message">
                <p class="success-message__item">
                  {{ $t("restorePassword.weSendEmail") }}
                  <span class="user-email">{{ form.email }}</span>
                </p>
                <p class="success-message__item">
                  {{ $t("restorePassword.followTheMailLink") }}
                </p>
                <p class="success-message__item">
                  {{ $t("restorePassword.pageCanBeClosed") }}
                </p>
              </div>
            </CCardBody>
            <CCardBody v-else class="restore-password-content">
              <div class="switcher-wrapper">
                <language-switcher></language-switcher>
              </div>
              <h1>{{ $t("restorePassword.title") }}</h1>
              <p class="text-muted">
                {{ $t("restorePassword.subtitlePasswordChange") }}
              </p>

              <form v-on:keydown.enter.prevent="submitPasswordChange">
                <CInput
                  :placeholder="$t('restorePassword.password')"
                  type="password"
                  autocomplete="new-password"
                  v-model="changePasswordForm.password"
                  :isValid="$v.changePasswordForm.password.isValidPassword"
                  :tooltipFeedback="false"
                ></CInput>
                <CInput
                  :placeholder="$t('restorePassword.passwordConfirm')"
                  type="password"
                  autocomplete="new-password"
                  v-model="changePasswordForm.confirmation"
                  :isValid="
                    $v.changePasswordForm.confirmation.isValidConfirmation
                  "
                  :tooltipFeedback="false"
                ></CInput>
              </form>
              <CRow class="restore-password-actions">
                <CCol col="6" class="text-left">
                  <CButton
                    color="primary"
                    class="px-4"
                    @click="submitPasswordChange()"
                    :disabled="!isChangePasswordFormValid"
                    >{{ $t("common.continue") }}</CButton
                  >
                </CCol>
              </CRow>
            </CCardBody>

            <div v-if="state === 'loading'" class="loading-overlay">
              <CSpinner color="primary" style="width: 3rem; height: 3rem" />
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import httpclient from "../../api/httpClient";
import utilMessages from "../../utils/messages";
import LanguageSwitcher from "../hauberk/components/LanguageSwitcher.vue";
import { isValidEmail } from "../../utils/validators";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "RestorePassword",
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      form: {
        email: "",
      },
      changePasswordForm: {
        password: "",
        confirmation: "",
        code: null,
      },
      emailSent: false,
      state: "",
    };
  },
  validations: {
    form: {
      email: {
        required,
        isValidEmail,
      },
    },
    changePasswordForm: {
      password: {
        required,
      },
      confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    },
  },
  mounted() {
    this.changePasswordForm.code = this.$route.query.code;
  },
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    async submitEmail() {
      this.state = "loading";
      httpclient
        .post("/send-password-reset-email", {
          email: this.form.email,
        })
        .then(() => {
          this.emailSent = true;
        })
        .catch((error) => {
          this.$toast.error(utilMessages.errMessage(error));
        })
        .finally(() => {
          this.state = "";
        });
    },
    async submitPasswordChange() {
      httpclient
        .post("/password-reset", {
          code: this.changePasswordForm.code,
          password: this.changePasswordForm.password,
        })
        .then(() => {
          this.$toast.success(this.$t("restorePassword.passwordChanged"));
          this.goToLogin();
        })
        .catch((error) => {
          this.$toast.error(utilMessages.errMessage(error));
        });
    },
  },
  computed: {
    isValid() {
      let email;
      if (this.$v.form.email.$dirty) {
        email = !this.$v.form.email.$invalid;
      }

      return {
        email: email,
      };
    },
    isEmailFormValid() {
      return !this.$v.form.$invalid;
    },
    isChangePasswordFormValid() {
      return !this.$v.changePasswordForm.$invalid;
    }
  },
};
</script>

<style>
.restore-password-page {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.restore-password-content {
  min-height: 264px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  position: relative;
}

.password-restore-container {
  position: relative;
}

.switcher-wrapper {
  position: absolute;
  right: 10px;
  top: 0;
}

.restore-password-actions {
  /* margin-top: auto; */
}

.verticaly-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 100%;
}

.text-align-center {
  vertical-align: center;
}

.user-email {
  font-weight: 700;
}

.email-content {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.success-message {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.success-message__item {
  margin: 0;
}

.loading-overlay {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00000020;
  z-index: 9999;
}
</style>
