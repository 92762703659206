/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


export const isValidCodeName = (value) => {
    return (/^[a-z0-9-]+$/.test(value))
}

export const isValidMSPID = (value) => {
    return (/^[A-Za-z0-9.-]+$/.test(value))
}

export const isValidHostname = (value) => {
    return isValidDomain(value) || isValidIP(value)
}

export const isValidDomain = (value) => {
    return (/^([a-zA-Z0-9._-]+\.[a-zA-Z]{2,15})$/.test(value))
}

export const isValidIP = (value) => {
    return (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(value))
}

export const isValidPort = (value) => {
    return (/^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/.test(value))
}

export const isValidEmail = (value) => {
    return (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value))
}